import api from "!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/css-loader/index.js??ref--14-1!../node_modules/sass-loader/lib/loader.js??ref--14-2!./styles.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};